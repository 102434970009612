<template>
    <el-dialog
        title="解锁"
        :visible.sync="dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        width="400px"
        center>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
            <el-form-item label="开始时间" prop="start_time" ref="start_time">
                <el-date-picker
                    v-model="ruleForm.start_time"
                    placeholder="开始时间"
                    type="datetime"
                    default-time="08:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="end_time" ref="end_time">
                <el-date-picker
                    v-model="ruleForm.end_time"
                    placeholder="结束时间,不选则不会锁定"
                    type="datetime"
                    default-time="18:00:00"
                    value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
            </el-form-item>
            <el-form-item class="flex align-center justify-around margin-top-sm" label-width="0">
                <el-button class="padding-lr-xl" @click="resetForm('ruleForm')" :loading="requestState">取消</el-button>
                <el-button class="padding-lr-xl" type="primary" @click="submitForm('ruleForm')" :loading="requestState">保存</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>

export default {
    name: "ProjectUnlockDialog",
    components: {},
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        pid: {
            type: Number,
            default() {
                return -1;
            }
        },

    },
    data() {
        return {
            ruleForm: {
                start_time: '',
                end_time: '',
            },
            rules: {
                start_time: [{required: true, message: '请选择', trigger: 'blur'}],
            },
            requestState:false
        }
    },
    computed: {},
    created() {
        this.init()
    },
    methods: {
        //  关闭
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.$emit('cancelDialog', false)
        },
        //  保存
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitData()
                }
            });
        },
        async submitData(){
            this.requestState = true
            this.$api.apiContent
                .projectUnlock({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    pid: this.pid,
                    start_time:this.ruleForm.start_time,
                    end_time:this.ruleForm.end_time,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            pid: this.pid,
                            start_time:this.ruleForm.start_time,
                            end_time:this.ruleForm.end_time,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.requestState = false
                    this.$message.success(res.msg);
                    this.$emit('cancelDialog', true)
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        }
    },
    watch: {
        dialog() {
            if (this.dialog) {
                this.$nextTick(() => {
                    this.$refs.ruleForm.resetFields();
                })
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .project-container{
        border-radius: 10px;
        border: 1px solid #f4f4f4;
        padding: 10px;
        overflow: auto;
        max-height: 200px;

        .delete{
            cursor: pointer;
        }
    }
</style>

<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理 > <b>项目列表</b></span>
            <!--<div class="flex-center"><i class="iconfont iconjiantou-copy-copy"></i>返回</div>-->
        </div>
        <div class="project-info">
            <div class="project-condition">
                <div class="flex-center">
                    <div class="send-condition-item">
                        <el-select v-model="state" placeholder="搜索字段">
                            <el-option
                                    v-for="item in stateOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <el-input
                            v-model="search_content"
                            placeholder="请输入搜索字段关键字"
                    ></el-input>
                    <el-button class="search" type="primary" @click="search">搜索</el-button>
                    <el-select v-model="end" placeholder="结项状态" clearable >
                        <el-option
                                v-for="item in endOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                    <el-date-picker
                            class="margin-left"
                            @change="timeChange"
                            v-model="start_end_time"
                            type="daterange"
                            value-format="timestamp"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>

                </div>
                <router-link to="/home/register">
                    <el-button type="primary">用章登记</el-button>
                </router-link>
                <el-button type="primary" @click="projectTransferDialog = true" v-if="isCWLD">项目转移</el-button>
                <router-link to="/home/add">
                    <el-button type="primary">新建项目</el-button>
                </router-link>
            </div>
            <div class="project-list">
                <el-table
                        :data="tableData"
                        :row-class-name="tableRowClassName"
                        header-row-class-name="table-header"
                        style="width: 100%"
                >
                    <el-table-column prop="pro_no" label="项目编号" width="150">
                        <template slot-scope="scope">
                            <div class="table-id">
                                <p v-for="item in scope.row.pro_no" :key="item">
                                    {{ item }}
                                </p>
                                <!--                {{scope.row.pro_no.join(',')}}-->
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="项目名称" width="150">
                        <template slot-scope="scope">
                            <div class="table-item name">
                                {{ scope.row.name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="项目日期" width="150">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.start_time }}至{{ scope.row.end_time }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="大会主席" width="150">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.chairman }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cat_name" label="项目类别" width="150">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.cat_name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="society" label="所属学会" width="170">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.society.join() }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="desc" label="备注" width="200">
                        <template slot-scope="scope">
                            <div class="table-remark" v-if="scope.row.desc">
                                <div :class="{more: scope.row.more,nomore: !scope.row.more}" style="white-space:pre-wrap;">{{scope.row.desc}}</div>
                                <span v-if="scope.row.hasMore" @click="showMore( scope.$index )">{{scope.row.more ? '收起更多':'查看更多'}}</span>
                            </div>
                            <div class="table-item" v-else>
                                --
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="admin" label="锁定状态" width="120">
                        <template slot-scope="scope">
                            <div class="table-item">
<!--                                is_lock_type  1 超时   2  比价-->
                                {{ scope.row.is_lock ? '已' : '未' }}锁定<span v-if="scope.row.is_lock">({{scope.row.is_lock_type === 1 ? '超时' : '比价'}})</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="admin" label="项目管理人(创建人)" width="120">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.admin }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="add_time" label="创建时间" width="110">
                        <template slot-scope="scope">
                            <div class="table-item">
                                {{ scope.row.add_time }}
                            </div>
                        </template>
                    </el-table-column>
                    <!--<el-table-column-->
                    <!--prop="state"-->
                    <!--label="项目优惠费率状态"-->
                    <!--width="110">-->
                    <!--<template slot-scope="scope">-->
                    <!--<div class="table-item">-->
                    <!--{{scope.row.state}}-->
                    <!--</div>-->
                    <!--</template>-->
                    <!--</el-table-column>-->
                    <el-table-column prop="operate" label="操作" width="200">
                        <template slot-scope="scope">
                            <div class="table-operate">
                                <router-link
                                        :to="{ name: 'detail', query: { id: scope.row.id,uid:scope.row.admin_id } }">
                                    <el-button type="text" size="small">查看详情</el-button>
                                </router-link>
                                <router-link
                                        :to="{ name: 'statistics', query: { id: scope.row.id,uid:scope.row.admin_id } }">
                                    <el-button type="text" size="small">数据统计</el-button>
                                </router-link>
                                <router-link :to="`list/evaluation?pid=${scope.row.id}`" v-if="scope.row.end_type">
                                    <el-button type="text" size="small">项目供应商评价</el-button>
                                </router-link>
                                <el-button type="text" size="small" @click="delItem(scope.row.id)" v-if="isCWLD">删除</el-button>
<!--                                TODO 解锁项目-->
                                <el-button type="text" size="small" @click="unlockItem(scope.row.id)" v-if="(isLD || scope.row.is_lock_role) && scope.row.is_lock">解锁</el-button>
                                <el-button type="text" size="small" @click="logItem(scope.row.id)" v-if="(isLD || scope.row.admin_id === user.mid)">解锁日志</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        class="page-all"
                        background
                        :page-size="limit"
                        layout="prev, pager, next"
                        :total="pageTotal"
                        :current-page.sync="page"
                        @current-change="setPage"
                >
                </el-pagination>
            </div>
        </div>

        <project-transfer-dialog :dialog="projectTransferDialog" @cancelDialog="cancelDialog"></project-transfer-dialog>
        <project-unlock-dialog :dialog="projectUnlockDialog" :pid="cur_pid" @cancelDialog="cancelDialog"></project-unlock-dialog>
        <log-record-dialog :dialog="logRecordDialog" :pid="cur_pid" @cancelDialog="cancelDialog"></log-record-dialog>
    </div>
</template>

<script>
    import config from "../../config/index";
    import {getTitleByType} from "../../tools";
    import ProjectTransferDialog from "./ProjectTransferDialog";
    import ProjectUnlockDialog from "./ProjectUnlockDialog";
    import LogRecordDialog from "./LogRecordDialog";
    export default {
        name: "list",
        components: {LogRecordDialog, ProjectUnlockDialog, ProjectTransferDialog},
        data() {
            return {
                isCWLD  :this.$store.state.user.mid === config.zhennggehong,
                isLD  :this.$store.state.user.mid === config.zhangque,
                state: 0,
                stateOptions: [
                    {
                        value: 0,
                        label: "全部",
                    },
                    {
                        value: 1,
                        label: "项目编号",
                    },
                    {
                        value: 2,
                        label: "项目名称",
                    },
                    {
                        value: 3,
                        label: "项目类别",
                    },
                    {
                        value: 4,
                        label: "所属学会",
                    },
                    {
                        value: 6,
                        label: "项目管理人",
                    },
                    {
                        value: 7,
                        label: "大会主席",
                    },
                ],
                endOptions: [
                    {
                        value: 0,
                        label: "全部",
                    },
                    {
                        value: 1,
                        label: "未结项",
                    },
                    {
                        value: 2,
                        label: "已结项",
                    }
                ],
                end:'',
                start_end_time: [],
                search_content: "",
                tableData: [],
                page: 1,
                pageTotal: 1,
                limit: 6,

                projectTransferDialog:false,
                cur_pid:'',
                projectUnlockDialog:false,
                logRecordDialog:false,
            };
        },
        computed: {
            user() {
                return this.$store.state.user;
            }
        },
        created() {
            this.$emit("setIndex", [1, 1]);
            this.init();
        },
        methods: {
            delItem(id){
                let that = this;
                that
                    .$confirm("删除后将删除所有相关信息，您确定要删除吗", "温馨提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                    .then(() => {
                        that.$api.apiContent
                            .del_pro({
                                token: that.$store.state.user.token,
                                timestamp: that.$tools.requestTime(),
                                id: id,
                                sign: that.$tools.SHA256(
                                    {
                                        token: that.$store.state.user.token,
                                        timestamp: that.$tools.requestTime(),
                                        id: id,
                                    },
                                    that.$store.state.user.key
                                ),
                            })
                            .then(() => {
                                that.$message.success("删除成功");
                                that.init();
                            })
                            .catch((e) => {
                                that.$message.error(e.msg);
                            });
                    })
                    .catch(() => {
                    });
            },
            unlockItem(id) {
                this.cur_pid = id
                this.projectUnlockDialog = true
            },
            logItem(id) {
                this.cur_pid = id
                this.logRecordDialog = true
            },
            cancelDialog(data){
                this.projectTransferDialog = false
                this.projectUnlockDialog = false
                this.logRecordDialog = false
                if (data) this.init()
            },
            search() {
                this.page = 1
                this.init();
            },
            timeChange() {
                this.page = 1
                this.init();
            },
            // 分页变化
            setPage(val) {
                this.page = val;
                this.init();
            },
            showMore(index){
                this.tableData[index].more = !this.tableData[index].more
            },
            //  页面加载时请求
            init() {
                this.$api.apiContent
                    .getProjectList({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        page: this.page,
                        limit: this.limit,
                        start_time:
                            this.start_end_time && this.start_end_time.length > 0
                                ? this.start_end_time[0] / 1000
                                : "",
                        end_time:
                            this.start_end_time && this.start_end_time.length > 0
                                ? ((this.start_end_time[1] / 1000)+(24*60*60))
                                : "",
                        state: this.state,
                        end:this.end ? this.end : 0,
                        name: this.search_content,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                page: this.page,
                                limit: this.limit,
                                start_time:
                                    this.start_end_time && this.start_end_time.length > 0
                                        ? this.start_end_time[0] / 1000
                                        : "",
                                end_time:
                                    this.start_end_time && this.start_end_time.length > 0
                                        ? ((this.start_end_time[1] / 1000)+(24*60*60))
                                        : "",
                                state: this.state,
                                end:this.end ? this.end : 0,
                                name: this.search_content,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.list.map((item) => {
                            item.add_time = this.$tools.ZHDate(new Date(item.add_time * 1000));
                            item.sp_time = this.$tools.ZHDate(new Date(item.sp_time * 1000));
                            item.start_time =  this.$tools.ZHDate(new Date(item.start_time * 1000));
                            let desc = item.desc +(item.desc ? '\n' : '')+  (item.name_list_count>0 ? (`${getTitleByType('会议名称',item.cid)}曾为:`  + item.name_list.map(name=>{
                                 return name.last_name
                                }).join(',') + '  已修改' + item.name_list_count
                                + '次\n' ) : '' )+  (item.sco_list_count>0 ? ( '会议曾属学会:' + item.sco_list.map(name=>{
                                    return name.last_sid.join('、')
                                }).join(',') + '  已修改' + item.sco_list_count
                                + '次\n' ) : '' )+ (item.time_list_count > 0? ( `${getTitleByType('会议日期',item.cid)}曾为:` + item.time_list.map(time=>{
                                return this.$tools.ZHDate(new Date(time.last_start_time * 1000)) + '至' + this.$tools.ZHDate(new Date(time.last_end_time * 1000))
                            }).join(',') + '  已修改' + item.time_list_count + '次') : '')
                            item.desc = desc
                            item.end_time = this.$tools.ZHDate(new Date(item.end_time * 1000));
                            item.hasMore = desc.length > 30
                            item.more = false
                        });
                        this.tableData = res.data.list;
                        this.pageTotal = res.data.count;
                    })
                    .catch(() => {
                        this.tableData = [];
                    });
            },
            tableRowClassName({row}) {
                return row.lock_num.color;
            }
        },
        watch:{
            end(){
                this.init()
            }
        }
    };
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }
            }
        }

        .project-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            overflow: hidden;

            .project-condition {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 24px 28px 16px;
                border-bottom: 2px solid #f5f5f5;
                .search{
                    margin: 0 20px;
                }
                .el-input {
                    margin-left: 20px;
                    width: 250px;
                }
                .el-date-editor{
                    width: 250px;
                }
            }

            .project-list {
                padding: 34px 28px;
                min-height: 700px;

                .table-id {
                    text-align: center;
                    font-size: 16px;
                    color: #666666;
                }

                .table-item {
                    text-align: center;
                    font-size: 16px;
                    color: #666666;
                }
                .table-remark{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    color: #666;
                    font-size: 14px;
                    .nomore{
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }
                    .more{
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        -webkit-line-clamp: 100;
                    }
                    span{
                        display: inline-block;
                        color: #3b77e7;
                        align-self: flex-end;
                    }
                }

                .table-operate {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    font-size: 16px;
                    color: #666666;

                    .el-button {
                        margin-left: 0px;
                        font-size: 16px;
                    }
                }

                .page-all {
                    text-align: center;
                    padding: 30px 0 0;
                }
            }
        }
    }
</style>

<template>
    <el-drawer
        title="解锁日志"
        :visible.sync="dialog"
        size="500px"
        :before-close="resetForm">
        <el-divider></el-divider>
        <div class="flex flex-direction padding-lr">
            <el-timeline reverse>
                <el-timeline-item
                    v-for="(activity, index) in lists"
                    :key="index"
                    :timestamp="activity.create_time">
                    <div class="flex flex-direction">
                        <div class="label"><span>[{{activity.userName}}]</span>解锁了项目</div>
                        <div class="remark">解锁时间：{{activity.start_time}}至{{activity.end_time}}</div>
                    </div>
                </el-timeline-item>
            </el-timeline>
            <el-empty class="self-center" description="暂无记录" image-size="200" v-if="!lists.length"></el-empty>
        </div>
    </el-drawer>

</template>

<script>


export default {
    name: "LogRecordDialog",
    components: {},
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        pid: {
            type: [String,Number],
            default: ''
        }

    },
    data() {
        return {
           lists:[]
        }
    },
    computed: {},
    created() {

    },
    methods: {
        getList() {
            //  获取项目分类
            this.$api.apiContent.projectUnlockLog({
                token: this.$store.state.user.token,
                timestamp: this.$tools.requestTime(),
                pid:this.pid,
                sign: this.$tools.SHA256({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    pid:this.pid,
                }, this.$store.state.user.key)
            }).then(res => {
                this.lists = res.msg || []
            }).catch(() => {
                this.lists = []
            })
        },
        //  关闭
        resetForm() {
            this.$emit('cancelDialog', false)
        },

    },
    filters:{
        state(state){
            //1：提交待审核 2：审核通过 3：驳回 4：驳回再提交
            switch (Number(state)) {
                case 1:
                    return '待审核'
                case 2:
                    return '已通过'
                case 3:
                    return '已驳回'
                case 4:
                    return '驳回后提交'
                default:
                    return '未知'
            }

        }
    },
    watch: {
        dialog() {
            if (this.dialog) {
                this.getList()
            }
        }
    }
}
</script>

<style scoped lang="scss">
/deep/{
    .el-drawer__header {
        font-size: 18px;
        color: #333333;
    }
    .el-divider--horizontal {
        margin: 0 0 20px;
    }
    .el-timeline-item__timestamp.is-bottom {
        margin-bottom: 5px;
    }
}
.label{
    font-size: 16px;
    color: #333333;
    line-height: 1.6;
    span{
        color: #0d7fd7;
    }
}
.remark {
    font-size: 14px;
    color: red;
    margin-top: 12px;
}
</style>

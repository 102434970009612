<template>
    <el-dialog
        title="项目转移"
        :visible.sync="dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        width="600px"
        center>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" label-position="top">
            <div class="flex align-center">
                <el-select style="width: 100%;" filterable v-model="project"  placeholder="请选择项目">
                    <el-option
                        v-for="item in projects"
                        :key="item.pid"
                        :label="`${item.name}(${item.user_name})`"
                        :value="item.pid">
                    </el-option>
                </el-select>
                <el-button class="margin-left-sm" type="primary" @click="addProject">添加</el-button>
            </div>
            <el-form-item :label="`已选择(${ruleForm.pids.length}个)`" prop="pids" ref="pids">
                <div class="project-container flex flex-direction">
                    <div class="flex align-center justify-between" v-for="(item,index) in ruleForm.pids" :key="index">
                        <el-tooltip :content="item.name">
                            <div class="title ovHide" placement="top">{{item.name}}</div>
                        </el-tooltip>
                        <i class="delete margin-left-sm el-icon-delete" @click="delItem(index)"></i>
                    </div>
                    <el-empty class="self-center" description="请先添加项目" :image-size="50" v-if="!ruleForm.pids.length"></el-empty>
                </div>
            </el-form-item>
            <el-form-item label="项目负责人" prop="member" ref="member">
                <el-select style="width: 100%;" filterable v-model="ruleForm.member" placeholder="请选择项目负责人">
                    <el-option
                        v-for="item in members"
                        :key="item.user_id"
                        :label="item.true_name"
                        :value="item.user_id">
                    </el-option>
                </el-select>
            </el-form-item>
            <div class="text-red">
                注：尽量一次转移不超过20个项目，且不要连续点击。<br>
                    转移时，最好不要提交新的审批，可能会造成部分数据无法转移。
            </div>
            <el-form-item class="flex align-center justify-around margin-top-sm" label-width="0">
                <el-button class="padding-lr-xl" @click="resetForm('ruleForm')" :loading="requestState">取消</el-button>
                <el-button class="padding-lr-xl" type="primary" @click="submitForm('ruleForm')" :loading="requestState">保存</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>

export default {
    name: "ProjectTransferDialog",
    components: {},
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        pid: {
            type: Number,
            default() {
                return -1;
            }
        },

    },
    data() {
        return {
            members: [],
            projects: [],
            project:'',
            ruleForm: {
                pids: [],
                member: ''
            },
            rules: {
                pids: [{required: true, message: '请选择', trigger: 'blur'}],
                member: [{required: true, message: '请选择', trigger: 'blur'}],
            },
            requestState:false
        }
    },
    computed: {},
    created() {
        this.init()
    },
    methods: {
        init(){
            this.$api.apiContent
                .getAllProjectList({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                  this.projects  = res.data
                })
            this.$api.apiContent
                .getAllUserList({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                  this.members  = res.data
                })
        },
        addProject(){
            if (!this.project) return this.$message.error("请选择项目")
            if (this.ruleForm.pids.some(s=> s.pid === this.project)) return this.$message.error("已经添加该项目了")
            let find = this.projects.find(s=> { return s.pid === this.project});
            this.ruleForm.pids.push(find)
        },
        delItem(index) {
            this.ruleForm.pids.splice(index,1)
        },
        //  关闭
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.$emit('cancelDialog', false)
        },
        //  保存
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitData()
                }
            });
        },
        async submitData(){
            this.requestState = true
            let pid = this.ruleForm.pids.map(item=>{ return item.pid}).join(',');
            this.$api.apiContent
                .projectTransfer({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    pid: pid,
                    recipient_user_id:this.ruleForm.member,
                    sign: this.$tools.SHA256(
                        {
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            pid: pid,
                            recipient_user_id:this.ruleForm.member,
                        },
                        this.$store.state.user.key
                    ),
                })
                .then((res) => {
                    this.requestState = false
                    this.$message.success(res.msg);
                    this.$emit('cancelDialog', true)
                })
                .catch((e) => {
                    this.requestState = false
                    this.$message.error(e.msg);
                });
        }
    },
    watch: {
        dialog() {
            if (this.dialog) {
                this.$nextTick(() => {
                    this.$refs.ruleForm.resetFields();
                })
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .project-container{
        border-radius: 10px;
        border: 1px solid #f4f4f4;
        padding: 10px;
        overflow: auto;
        max-height: 200px;

        .delete{
            cursor: pointer;
        }
    }
</style>
